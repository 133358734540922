import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import BaseMdxLayout from './BaseMdxLayout';
import { Heading1, Paragraph } from '../components/Typography';
import Link from '../components/Link';

const BackButton = styled(Link).attrs({
  children: 'Back to news',
  href: '/news',
  textDecoration: 'none',
  fontWeight: '600',
  color: 'white',
  fontSize: '16px',
  backgroundColor: 'transparent',
  border: '2px solid white',
  borderRadius: '25px',
  transition: '0.5s',
  hoverColor: 'white',
  paddingBottom: '9px',
  paddingLeft: '20px',
  paddingRight: '20px',
  paddingTop: '9px',
})`
  text-decoration: none;
  color: white;
  font-weight: 600;
  font-size: 16px;
  background-color: transparent;
  border: 2px solid white;
  padding: 9px 20px;
  border-radius: 25px;
  transition: 0.5s;
  cursor: pointer;

  :hover {
    background-color: rgba(255, 255, 255, 0.24);
  }
`;

const NewsLayout = ({
  data, // this prop will be injected by the GraphQL query below.
}) => {
  const { body, frontmatter } = data.mdx;

  const handleGoBack = useCallback((event) => {
    event.preventDefault();
    window.history.back();
  }, []);

  return (
    <BaseMdxLayout
      heroContent={
        <>
          <Heading1
            fontSize='2.4rem !important'
            marginTop='120px'
            maxWidth='800px'
          >
            {frontmatter.title}
          </Heading1>
          <Paragraph fontSize='0.95rem'>{frontmatter.date}</Paragraph>
          <BackButton onClick={handleGoBack} />
        </>
      }
    >
      {body}
    </BaseMdxLayout>
  );
};

NewsLayout.propTypes = {
  data: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
  query($path: String!) {
    mdx(fields: { slug: { eq: $path } }) {
      body
      frontmatter {
        date(formatString: "DD MMM YYYY")
        title
      }
    }
  }
`;

export default NewsLayout;
